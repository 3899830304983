html,
body,
#root,
.scroll {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-snap-type: y proximity;
}

.scroll > div {
  scroll-snap-align: start;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #212121 0%, #101010 80%);
  font-family: ff-basic-gothic-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.dot {
  pointer-events: none;
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  max-width: 800px;
  padding-left: 250px;
  line-height: 1.6em;
  font-size: 15px;
  letter-spacing: 1.5px;
  height: 100vh;
  color: #8d9aa2;
}

/* .l-blue {
  color: #677a86;
}

.l-pink {
  color: #7a656e;
}

.l-green {
  color: #7f876f;
}

.l-blue h1 {
  color: #e7f6ff;
}

.l-pink h1 {
  color: #f9e8ef;
}

.l-green h1 {
  color: #fbfef5;
} */

.dot h1 {
  color: #e7f6ff;
  -webkit-font-smoothing: auto;
  pointer-events: none;
  font-size: 4em;
  font-weight: 100;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
}

.caption {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 50px;
  color: white;
  font-size: 6em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}
