.header {
  max-height: 300px;
  padding: 10px 15px 10px 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.logo-light {
  width: 200px;
}

.main-nav {
  text-align: right;
}

.main-nav ul {
  margin: 0;
}

.main-nav li {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #bccdd8;
  list-style: none;
  margin-bottom: 2px;
  font-size: 11px;
  display: inline-block;
}

a {
  text-decoration: none;
}

.logo-container .cls-1 {
  fill: none !important;
}

@media only screen and (min-width: 768px) {
  .main-nav li {
    margin-bottom: 5px;
    font-size: 14px;
    display: block;
  }
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 15px;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2px;
  background: #bccdd8;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.main-navigation__drawer-nav ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.main-navigation__drawer-nav ul a {
  width: 120px;
}

.gif-ctn {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  bottom: 10px;
  left: 10px;
  right: 5px;
}

.gif-ctn img {
  display: block;
  width: 99px;
}

.footer-other-links {
  display: flex;
  width: 129px !important;
  justify-content: space-between;
  align-items: flex-end;
}

.ig-credit {
  margin: 0;
}

.ig-credit a {
  color: tomato;
  font-family: ff-basic-gothic-pro, sans-serif;
  font-size: 12px;
}
.footer-credit {
  color: #bccdd8;
  font-family: ff-basic-gothic-pro, sans-serif;
  font-size: 12px;
  margin-top: 3px;
  display: block;
}

.credit-ctn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .main-header {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
