* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fbfbfd;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #f15946;
}

#home-c {
  /* background: url('./models/testbg.jpg') repeat center center; */
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.opacity:hover {
  opacity: 0.8;
}

.primary-color {
  color: #f2e74b;
}

.accent-color {
  color: #c09cd9;
}

.test {
  background-color: blue;
}

#model-credit {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: yellow;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-size: 12px;
}

a:hover,
a:hover li {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .header {
    padding: 8px !important;
    align-items: flex-start;
  }
  .logo-light {
    width: 149px !important;
  }
  #model-credit {
    bottom: 5px;
    right: 5px;
  }
  .sound-btn {
    bottom: 5px !important;
    left: 5px !important;
  }
}
