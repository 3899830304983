.side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  min-height: 100vh;
  width: 90%;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(33px);
  padding: 10px 10px 0 20px;
  min-height: -webkit-fill-available;
}

.slide-in-right-enter {
  transform: translateX(100%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms;
}

.slide-in-right-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-right-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 300ms;
}
