.btn-ctn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #090909;
}

#render-canvas {
  background-color: black;
  color: white;
  z-index: 1000;
  border: none;
  width: 100px;
  height: 30px;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  border: 1px solid white;
  line-height: 10px;
  cursor: pointer;
}
