#not-found-c {
  background-color: #010440;
}

.content h5 {
  width: 250px;
  margin-left: 20px;
}

.not-found a {
  font-family: halyard-display, sans-serif;
  font-weight: 400;
  font-style: normal;
}
