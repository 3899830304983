.sound-btn {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: black;
  color: white;
  z-index: 1000;
  border: none;
  width: 100px;
  height: 30px;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  border: 1px solid white;
  line-height: 10px;
  cursor: pointer;
}

.sound-btn:focus {
  outline: none;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .sound-btn {
    left: 140px;
  }
}

@media screen and (min-width: 1200px) {
  .sound-btn {
    left: 150px;
  }
}
