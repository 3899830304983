.ends {
  padding: 20px 60px 20px 15px !important;
  position: relative;
}

.ends:hover {
  opacity: 0.8;
}

.audio-note {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  color: tomato;
  font-size: 12px;
  text-align: center;
  line-height: 1.5em;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
}
