.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}

.fullscreen.ready > div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.fullscreen > div {
  color: #fff;
  padding: 9px 30px;
  border: 1px solid #fff;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
  cursor: pointer;
}

.ends {
  padding: 20px 60px 20px 15px !important;
  position: relative;
}

.ends:hover {
  opacity: 0.8;
}

.audio-note {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  color: tomato;
  font-size: 12px;
  text-align: center;
  line-height: 1.5em;
  font-family: dunbar-text, sans-serif;
  font-weight: 700;
}
