#main-link {
  position: absolute;
  z-index: 99;
  bottom: 5px;
  font-size: 16px;
  line-height: 16px;
  color: #9dfe0d;
  font-weight: 400;
}

#main-link span {
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
}

#main-link:hover span {
  animation-duration: 1s;
  animation-name: rotate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}

@media screen and (max-width: 767px) {
  #root > #main-link {
    display: none;
  }

  .footer-other-links #main-link {
    position: static;
    font-size: 13px;
    line-height: 13px;
  }

  .footer-other-links #main-link span {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  #main-link {
    left: 10px;
    bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  #main-link {
    font-size: 23px;
    bottom: 12px;
    line-height: 23px;
  }
}

@media screen and (min-width: 1200px) {
  #main-link {
    font-size: 26px;
    bottom: 13px;
    line-height: 26px;
  }
}

@keyframes rotate {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, -5px) rotate(15deg);
  }
  50% {
    transform: translate(0px, -10px) skew(-15deg, -15deg);
  }
  63% {
    transform: translate(-5px, -5px);
  }
  76% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
